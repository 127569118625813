var nativeCodeNumber = [{
  label: "Afganistán",
  value: "93"
}, {
  label: "Albania",
  value: "355"
}, {
  label: "Alemania",
  value: "49"
}, {
  label: "Algeria",
  value: "213"
}, {
  label: "Andorra",
  value: "376"
}, {
  label: "Angola",
  value: "244"
}, {
  label: "Anguila",
  value: "1 264"
}, {
  label: "Antártida",
  value: "672"
}, {
  label: "Antigua y Barbuda",
  value: "1 268"
}, {
  label: "Antillas Neerlandesas",
  value: "599"
}, {
  label: "Arabia Saudita",
  value: "966"
}, {
  label: "Argentina",
  value: "54"
}, {
  label: "Armenia",
  value: "374"
}, {
  label: "Aruba",
  value: "297"
}, {
  label: "Australia",
  value: "61"
}, {
  label: "Austria",
  value: "43"
}, {
  label: "Azerbayán",
  value: "994"
}, {
  label: "Bélgica",
  value: "32"
}, {
  label: "Bahamas",
  value: "1 242"
}, {
  label: "Bahrein",
  value: "973"
}, {
  label: "Bangladesh",
  value: "880"
}, {
  label: "Barbados",
  value: "1 246"
}, {
  label: "Belice",
  value: "501"
}, {
  label: "Benín",
  value: "229"
}, {
  label: "Bhután",
  value: "975"
}, {
  label: "Bielorrusia",
  value: "375"
}, {
  label: "Birmania",
  value: "95"
}, {
  label: "Bolivia",
  value: "591"
}, {
  label: "Bosnia y Herzegovina",
  value: "387"
}, {
  label: "Botsuana",
  value: "267"
}, {
  label: "Brasil",
  value: "55"
}, {
  label: "Brunéi",
  value: "673"
}, {
  label: "Bulgaria",
  value: "359"
}, {
  label: "Burkina Faso",
  value: "226"
}, {
  label: "Burundi",
  value: "257"
}, {
  label: "Cabo Verde",
  value: "238"
}, {
  label: "Camboya",
  value: "855"
}, {
  label: "Camerún",
  value: "237"
}, {
  label: "Canadá",
  value: "1"
}, {
  label: "Chad",
  value: "235"
}, {
  label: "Chile",
  value: "56"
}, {
  label: "China",
  value: "86"
}, {
  label: "Chipre",
  value: "357"
}, {
  label: "Ciudad del Vaticano",
  value: "39"
}, {
  label: "Colombia",
  value: "57"
}, {
  label: "Comoras",
  value: "269"
}, {
  label: "Congo",
  value: "242"
}, {
  label: "Congo",
  value: "243"
}, {
  label: "Corea del Norte",
  value: "850"
}, {
  label: "Corea del Sur",
  value: "82"
}, {
  label: "Costa de Marfil",
  value: "225"
}, {
  label: "Costa Rica",
  value: "506"
}, {
  label: "Croacia",
  value: "385"
}, {
  label: "Cuba",
  value: "53"
}, {
  label: "Dinamarca",
  value: "45"
}, {
  label: "Dominica",
  value: "1 767"
}, {
  label: "Ecuador",
  value: "593"
}, {
  label: "Egipto",
  value: "20"
}, {
  label: "El Salvador",
  value: "503"
}, {
  label: "Emiratos Árabes Unidos",
  value: "971"
}, {
  label: "Eritrea",
  value: "291"
}, {
  label: "Eslovaquia",
  value: "421"
}, {
  label: "Eslovenia",
  value: "386"
}, {
  label: "España",
  value: "34"
}, {
  label: "Estados Unidos de América",
  value: "1"
}, {
  label: "Estonia",
  value: "372"
}, {
  label: "Etiopía",
  value: "251"
}, {
  label: "Filipinas",
  value: "63"
}, {
  label: "Finlandia",
  value: "358"
}, {
  label: "Fiyi",
  value: "679"
}, {
  label: "Francia",
  value: "33"
}, {
  label: "Gabón",
  value: "241"
}, {
  label: "Gambia",
  value: "220"
}, {
  label: "Georgia",
  value: "995"
}, {
  label: "Ghana",
  value: "233"
}, {
  label: "Gibraltar",
  value: "350"
}, {
  label: "Granada",
  value: "1 473"
}, {
  label: "Grecia",
  value: "30"
}, {
  label: "Groenlandia",
  value: "299"
}, {
  label: "Guadalupe",
  value: ""
}, {
  label: "Guam",
  value: "1 671"
}, {
  label: "Guatemala",
  value: "502"
}, {
  label: "Guayana Francesa",
  value: ""
}, {
  label: "Guernsey",
  value: ""
}, {
  label: "Guinea",
  value: "224"
}, {
  label: "Guinea Ecuatorial",
  value: "240"
}, {
  label: "Guinea-Bissau",
  value: "245"
}, {
  label: "Guyana",
  value: "592"
}, {
  label: "Haití",
  value: "509"
}, {
  label: "Honduras",
  value: "504"
}, {
  label: "Hong kong",
  value: "852"
}, {
  label: "Hungría",
  value: "36"
}, {
  label: "India",
  value: "91"
}, {
  label: "Indonesia",
  value: "62"
}, {
  label: "Irán",
  value: "98"
}, {
  label: "Irak",
  value: "964"
}, {
  label: "Irlanda",
  value: "353"
}, {
  label: "Isla Bouvet",
  value: ""
}, {
  label: "Isla de Man",
  value: "44"
}, {
  label: "Isla de Navidad",
  value: "61"
}, {
  label: "Isla Norfolk",
  value: ""
}, {
  label: "Islandia",
  value: "354"
}, {
  label: "Islas Bermudas",
  value: "1 441"
}, {
  label: "Islas Caimán",
  value: "1 345"
}, {
  label: "Islas Cocos (Keeling)",
  value: "61"
}, {
  label: "Islas Cook",
  value: "682"
}, {
  label: "Islas de Åland",
  value: ""
}, {
  label: "Islas Feroe",
  value: "298"
}, {
  label: "Islas Georgias del Sur y Sandwich del Sur",
  value: ""
}, {
  label: "Islas Heard y McDonald",
  value: ""
}, {
  label: "Islas Maldivas",
  value: "960"
}, {
  label: "Islas Malvinas",
  value: "500"
}, {
  label: "Islas Marianas del Norte",
  value: "1 670"
}, {
  label: "Islas Marshall",
  value: "692"
}, {
  label: "Islas Pitcairn",
  value: "870"
}, {
  label: "Islas Salomón",
  value: "677"
}, {
  label: "Islas Turcas y Caicos",
  value: "1 649"
}, {
  label: "Islas Ultramarinas Menores de Estados Unidos",
  value: ""
}, {
  label: "Islas Vírgenes Británicas",
  value: "1 284"
}, {
  label: "Islas Vírgenes de los Estados Unidos",
  value: "1 340"
}, {
  label: "Israel",
  value: "972"
}, {
  label: "Italia",
  value: "39"
}, {
  label: "Jamaica",
  value: "1 876"
}, {
  label: "Japón",
  value: "81"
}, {
  label: "Jersey",
  value: ""
}, {
  label: "Jordania",
  value: "962"
}, {
  label: "Kazajistán",
  value: "7"
}, {
  label: "Kenia",
  value: "254"
}, {
  label: "Kirgizstán",
  value: "996"
}, {
  label: "Kiribati",
  value: "686"
}, {
  label: "Kuwait",
  value: "965"
}, {
  label: "Líbano",
  value: "961"
}, {
  label: "Laos",
  value: "856"
}, {
  label: "Lesoto",
  value: "266"
}, {
  label: "Letonia",
  value: "371"
}, {
  label: "Liberia",
  value: "231"
}, {
  label: "Libia",
  value: "218"
}, {
  label: "Liechtenstein",
  value: "423"
}, {
  label: "Lituania",
  value: "370"
}, {
  label: "Luxemburgo",
  value: "352"
}, {
  label: "México",
  value: "52"
}, {
  label: "Mónaco",
  value: "377"
}, {
  label: "Macao",
  value: "853"
}, {
  label: "Macedônia",
  value: "389"
}, {
  label: "Madagascar",
  value: "261"
}, {
  label: "Malasia",
  value: "60"
}, {
  label: "Malawi",
  value: "265"
}, {
  label: "Mali",
  value: "223"
}, {
  label: "Malta",
  value: "356"
}, {
  label: "Marruecos",
  value: "212"
}, {
  label: "Martinica",
  value: ""
}, {
  label: "Mauricio",
  value: "230"
}, {
  label: "Mauritania",
  value: "222"
}, {
  label: "Mayotte",
  value: "262"
}, {
  label: "Micronesia",
  value: "691"
}, {
  label: "Moldavia",
  value: "373"
}, {
  label: "Mongolia",
  value: "976"
}, {
  label: "Montenegro",
  value: "382"
}, {
  label: "Montserrat",
  value: "1 664"
}, {
  label: "Mozambique",
  value: "258"
}, {
  label: "Namibia",
  value: "264"
}, {
  label: "Nauru",
  value: "674"
}, {
  label: "Nepal",
  value: "977"
}, {
  label: "Nicaragua",
  value: "505"
}, {
  label: "Niger",
  value: "227"
}, {
  label: "Nigeria",
  value: "234"
}, {
  label: "Niue",
  value: "683"
}, {
  label: "Noruega",
  value: "47"
}, {
  label: "Nueva Caledonia",
  value: "687"
}, {
  label: "Nueva Zelanda",
  value: "64"
}, {
  label: "Omán",
  value: "968"
}, {
  label: "Países Bajos",
  value: "31"
}, {
  label: "Pakistán",
  value: "92"
}, {
  label: "Palau",
  value: "680"
}, {
  label: "Palestina",
  value: ""
}, {
  label: "Panamá",
  value: "507"
}, {
  label: "Papúa Nueva Guinea",
  value: "675"
}, {
  label: "Paraguay",
  value: "595"
}, {
  label: "Perú",
  value: "51"
}, {
  label: "Polinesia Francesa",
  value: "689"
}, {
  label: "Polonia",
  value: "48"
}, {
  label: "Portugal",
  value: "351"
}, {
  label: "Puerto Rico",
  value: "1"
}, {
  label: "Qatar",
  value: "974"
}, {
  label: "Reino Unido",
  value: "44"
}, {
  label: "República Centroafricana",
  value: "236"
}, {
  label: "República Checa",
  value: "420"
}, {
  label: "República Dominicana",
  value: "1 809"
}, {
  label: "Reunión",
  value: ""
}, {
  label: "Ruanda",
  value: "250"
}, {
  label: "Rumanía",
  value: "40"
}, {
  label: "Rusia",
  value: "7"
}, {
  label: "Sahara Occidental",
  value: ""
}, {
  label: "Samoa",
  value: "685"
}, {
  label: "Samoa Americana",
  value: "1 684"
}, {
  label: "San Bartolomé",
  value: "590"
}, {
  label: "San Cristóbal y Nieves",
  value: "1 869"
}, {
  label: "San Marino",
  value: "378"
}, {
  label: "San Martín (Francia)",
  value: "1 599"
}, {
  label: "San Pedro y Miquelón",
  value: "508"
}, {
  label: "San Vicente y las Granadinas",
  value: "1 784"
}, {
  label: "Santa Elena",
  value: "290"
}, {
  label: "Santa Lucía",
  value: "1 758"
}, {
  label: "Santo Tomé y Príncipe",
  value: "239"
}, {
  label: "Senegal",
  value: "221"
}, {
  label: "Serbia",
  value: "381"
}, {
  label: "Seychelles",
  value: "248"
}, {
  label: "Sierra Leona",
  value: "232"
}, {
  label: "Singapur",
  value: "65"
}, {
  label: "Siria",
  value: "963"
}, {
  label: "Somalia",
  value: "252"
}, {
  label: "Sri lanka",
  value: "94"
}, {
  label: "Sudáfrica",
  value: "27"
}, {
  label: "Sudán",
  value: "249"
}, {
  label: "Suecia",
  value: "46"
}, {
  label: "Suiza",
  value: "41"
}, {
  label: "Surinám",
  value: "597"
}, {
  label: "Svalbard y Jan Mayen",
  value: ""
}, {
  label: "Swazilandia",
  value: "268"
}, {
  label: "Tadjikistán",
  value: "992"
}, {
  label: "Tailandia",
  value: "66"
}, {
  label: "Taiwán",
  value: "886"
}, {
  label: "Tanzania",
  value: "255"
}, {
  label: "Territorio Británico del Océano Índico",
  value: ""
}, {
  label: "Territorios Australes y Antárticas Franceses",
  value: ""
}, {
  label: "Timor Oriental",
  value: "670"
}, {
  label: "Togo",
  value: "228"
}, {
  label: "Tokelau",
  value: "690"
}, {
  label: "Tonga",
  value: "676"
}, {
  label: "Trinidad y Tobago",
  value: "1 868"
}, {
  label: "Tunez",
  value: "216"
}, {
  label: "Turkmenistán",
  value: "993"
}, {
  label: "Turquía",
  value: "90"
}, {
  label: "Tuvalu",
  value: "688"
}, {
  label: "Ucrania",
  value: "380"
}, {
  label: "Uganda",
  value: "256"
}, {
  label: "Uruguay",
  value: "598"
}, {
  label: "Uzbekistán",
  value: "998"
}, {
  label: "Vanuatu",
  value: "678"
}, {
  label: "Venezuela",
  value: "58"
}, {
  label: "Vietnam",
  value: "84"
}, {
  label: "Wallis y Futuna",
  value: "681"
}, {
  label: "Yemen",
  value: "967"
}, {
  label: "Yibuti",
  value: "253"
}, {
  label: "Zambia",
  value: "260"
}, {
  label: "Zimbabue",
  value: "263"
}];
export default nativeCodeNumber;