function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import defaultPoliza from "./defaultPoliza";
import defaultClient from "../clients/defaultClient";
import defaultItemAsegurado from "../itemAsegurado/defaultItemAsegurado";
import defaultPaymentInformation from "../paymentInformation/defaultPaymentInformation";
import defaultBrokerInformation from "../brokerInformation/defaultBrokerInformation";
export var checkIsFormValid = function checkIsFormValid(polizaForm) {
  return true;
};
export var generateDefaultPoliza = function generateDefaultPoliza(_ref) {
  var poliza = _ref.poliza,
      beneficiario = _ref.beneficiario,
      contratante = _ref.contratante,
      asegurado = _ref.asegurado,
      itemAsegurado = _ref.itemAsegurado,
      payment_information = _ref.payment_information,
      broker_information = _ref.commission;
  var id = poliza.id,
      company_id = poliza.company_id,
      first_payment_due_date = poliza.first_payment_due_date,
      tipo_de_pago = poliza.tipo_de_pago,
      number_of_cuotes = poliza.number_of_cuotes,
      old_poliza_code = poliza.old_poliza_code,
      renewal = poliza.renewal,
      start_date = poliza.start_date,
      finish_date = poliza.finish_date,
      general_condition_code = poliza.general_condition_code,
      first_expiration_date = poliza.first_expiration_date,
      code = poliza.code,
      currency = poliza.currency,
      first_cuote = poliza.first_cuote,
      prima_neta = poliza.prima_neta,
      ramo = poliza.ramo,
      emission_date = poliza.emission_date,
      is_prepaid = poliza.is_prepaid,
      show_transfer_payment = poliza.show_transfer_payment,
      company = poliza.company,
      draft = poliza.draft,
      channel_id = poliza.channel_id,
      materia_asegurada_formatted = poliza.materia_asegurada_formatted;
  return _objectSpread(_objectSpread({}, defaultPoliza), {}, {
    id: id,
    company: company,
    emission_date: emission_date && generateDate(emission_date),
    company_id: company_id,
    channel_id: channel_id,
    first_payment_due_date: first_payment_due_date && generateDate(first_payment_due_date),
    start_date: start_date && generateDate(start_date),
    finish_date: finish_date && generateDate(finish_date, null),
    first_expiration_date: first_expiration_date && generateDate(first_expiration_date, null),
    tipo_de_pago: tipo_de_pago,
    number_of_cuotes: number_of_cuotes,
    old_poliza_code: old_poliza_code,
    renewal: renewal,
    draft: draft,
    general_condition_code: general_condition_code,
    materia_asegurada_formatted: materia_asegurada_formatted,
    code: code,
    currency: currency,
    ramo: ramo,
    first_cuote: first_cuote,
    prima_neta: prima_neta,
    is_prepaid: is_prepaid,
    show_transfer_payment: show_transfer_payment,
    beneficiario: generateDefaultUser(beneficiario),
    contratante: generateDefaultUser(contratante),
    asegurado: generateDefaultUser(asegurado),
    item_asegurado: generateDefaultItemAsegurado(itemAsegurado),
    payment_information: generatePaymentInformation(payment_information),
    broker_information: generateBrokerInformation(broker_information)
  });
};
export var formatDate = function formatDate(date) {
  if (date && date.split("-").length === 3) {
    var _date$split = date.split("-"),
        _date$split2 = _slicedToArray(_date$split, 3),
        year = _date$split2[0],
        month = _date$split2[1],
        day = _date$split2[2];

    return "".concat(day, "-").concat(month, "-").concat(year);
  }

  return null;
};
export var generateDate = function generateDate(date) {
  var defaultDate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();

  if (date && date.split("-").length === 3) {
    // String formatted mode
    var _date$split3 = date.split("-"),
        _date$split4 = _slicedToArray(_date$split3, 3),
        day = _date$split4[0],
        month = _date$split4[1],
        year = _date$split4[2];

    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
  } else if (date) {
    //String mode
    return new Date(date);
  }

  return defaultDate;
};

var generateDefaultUser = function generateDefaultUser(user) {
  if (!user) return defaultClient;
  var id = user.id,
      name = user.name,
      rut = user.rut,
      emails = user.emails,
      addresses = user.addresses,
      street = user.street,
      phone = user.phone,
      region_id = user.region_id,
      comuna_id = user.comuna_id,
      city_id = user.city_id,
      phone_code_number = user.phone_code_number;
  return _objectSpread(_objectSpread({}, defaultClient), {}, {
    id: id,
    name: name,
    rut: rut,
    addresses: addresses || defaultClient.addresses,
    emails: emails || defaultClient.emails,
    street: street,
    phone: phone,
    phone_code_number: phone_code_number,
    region_id: region_id,
    comuna_id: comuna_id,
    city_id: city_id
  });
};

var generateDefaultItemAsegurado = function generateDefaultItemAsegurado(itemAsegurado) {
  if (!itemAsegurado) return defaultItemAsegurado;
  return _objectSpread(_objectSpread({}, defaultItemAsegurado), itemAsegurado);
};

var generatePaymentInformation = function generatePaymentInformation(paymentInformation) {
  if (!paymentInformation) return defaultPaymentInformation;
  return _objectSpread(_objectSpread({}, defaultPaymentInformation), paymentInformation);
};

export function addMonths(date, months) {
  var newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);
  return newDate;
}

var generateBrokerInformation = function generateBrokerInformation(brokerInformation) {
  if (!brokerInformation) return defaultBrokerInformation;
  return _objectSpread(_objectSpread({}, defaultBrokerInformation), brokerInformation);
};