import defaultClient from "../../components/clients/defaultClient";
import defaultItemAsegurado from "../itemAsegurado/defaultItemAsegurado";
import defaultPaymentInformation from "../paymentInformation/defaultPaymentInformation";
import defaultBrokerInformation from "../brokerInformation/defaultBrokerInformation";
import { CurrencyTypes } from "../../utils/data";
import { addMonths } from "../polizas/utils";
var defaultPoliza = {
  id: null,
  company_id: null,
  first_payment_due_date: new Date(),
  beneficiario: defaultClient,
  asegurado: defaultClient,
  contratante: defaultClient,
  item_asegurado: defaultItemAsegurado,
  tipo_de_pago: null,
  number_of_cuotes: null,
  start_date: null,
  finish_date: null,
  first_cuote: null,
  old_poliza_code: null,
  renewal: false,
  general_condition_code: null,
  code: null,
  currency: CurrencyTypes.UF,
  ramo: null,
  document: null,
  assist_card: null,
  additional_files: null,
  prima_neta: null,
  first_expiration_date: addMonths(new Date(), 1),
  emission_date: new Date(),
  is_prepaid: false,
  show_transfer_payment: false,
  payment_information: defaultPaymentInformation,
  broker_information: defaultBrokerInformation,
  document_actions: null,
  assist_card_actions: null,
  additional_files_actions: [],
  draft: true
};
export default defaultPoliza;