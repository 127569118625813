import codeNumberData from "./codeNumbers.data";
var helpers = {
  findDuplicates: function findDuplicates(codeNumbers, inputCodeNumber) {
    return codeNumbers.filter(function (codeNumber) {
      return codeNumber.value === inputCodeNumber.value;
    });
  },
  mergeDuplicates: function mergeDuplicates(duplicates) {
    return duplicates.reduce(function (acc, codeNumber) {
      return {
        label: "".concat(acc.label, " | ").concat(codeNumber.label),
        value: acc.value
      };
    });
  },
  alreadyExist: function alreadyExist(codeNumbersInput, codeNumber) {
    return codeNumbersInput.some(function (c) {
      return c.value === codeNumber.value;
    });
  }
};

var clearCodeNumbers = function clearCodeNumbers(inputCodeNumbers) {
  return inputCodeNumbers.filter(function (codeNumber) {
    return codeNumber.value !== null && codeNumber.value !== "";
  });
};

var formatCodeNumbers = function formatCodeNumbers(codeNumbersInput) {
  var codeNumbersWithoutDuplicates = [];
  codeNumbersInput.forEach(function (codeNumber) {
    var duplicates = helpers.findDuplicates(codeNumbersInput, codeNumber);
    var existDuplicates = duplicates.length > 1; // Already exist case

    if (helpers.alreadyExist(codeNumbersWithoutDuplicates, codeNumber)) return null; // Duplicate case

    if (existDuplicates) {
      var newCodeNumber = helpers.mergeDuplicates(duplicates);
      return codeNumbersWithoutDuplicates.push(newCodeNumber);
    } // Singular case  (don't exist duplicates)


    return codeNumbersWithoutDuplicates.push(codeNumber);
  });
  return codeNumbersWithoutDuplicates;
};

var codeNumbers = function codeNumbers(inputCodeNumber) {
  var codeNumbersCleared = clearCodeNumbers(inputCodeNumber);
  var codeNumbersFormatted = formatCodeNumbers(codeNumbersCleared);
  return codeNumbersFormatted;
};

export default codeNumbers(codeNumberData);