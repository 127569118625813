export default [{
  label: "BUS",
  value: "BUS"
}, {
  label: "CAMIÓN",
  value: "CAMIÓN"
}, {
  label: "TRACTO CAMIÓN",
  value: "TRACTO CAMIÓN"
}, {
  label: "SEMIRREMOLQUE",
  value: "SEMIRREMOLQUE"
}, {
  label: "REMOLQUE",
  value: "REMOLQUE"
}, {
  label: "ACOPLADO",
  value: "ACOPLADO"
}, {
  label: "RAMPLA",
  value: "RAMPLA"
}];