export default [{
  label: "CAMIONETA",
  value: "CAMIONETA"
}, {
  label: "SUV",
  value: "SUV"
}, {
  label: "STATION WAGON",
  value: "STATION WAGON"
}, {
  label: "JEEP",
  value: "JEEP"
}, {
  label: "AUTOMOVIL",
  value: "AUTOMOVIL"
}];