var defaultBrokerInformation = {
  commission_affect: 0,
  commission_exempt: 0,
  broker_commission: 0,
  agent_commission: 0,
  agent: null,
  commission_percentage: 0,
  affects_net_bonus: 0,
  exempt_net_bonus: 0,
  net_bonus: 0,
  iva: 0,
  total_bonus: 0
};
export default defaultBrokerInformation;