import PrimaForm from "../PrimaForm";
import UserForm from "../UserForm";
import AmountForm from "./AmountForm";
import AnnulmentForm from "./AnnulmentForm";
import CancellationForm from "./CancellationForm";
import ExtensionForm from "./ExtensionForm";
import ReenableForm from "./ReenableForm";
export default {
  IncreaseAmountForm: AmountForm,
  DecreaseAmountForm: AmountForm,
  Minute: PrimaForm,
  ExtensionForm: ExtensionForm,
  CancellationForm: CancellationForm,
  AnnulmentForm: AnnulmentForm,
  ReenableForm: ReenableForm,
  UserForm: UserForm
};