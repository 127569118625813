import ProposedIncome from "./ProposedIncome";
import ProposedCover from "./ProposedCover";
import InsuredItems from "./InsuredItems";
import PaymentMethod from "./PaymentMethod";
import BrokerInformation from "./BrokerInformation";
import PolizaGroupButtons from "./PolizaGroupButtons";
import EndorsementInformation from "./EndorsementInformation";
export default {
  ProposedIncome: ProposedIncome,
  ProposedCover: ProposedCover,
  InsuredItems: InsuredItems,
  PaymentMethod: PaymentMethod,
  BrokerInformation: BrokerInformation,
  PolizaGroupButtons: PolizaGroupButtons,
  EndorsementInformation: EndorsementInformation
};