export var getYearsByRange = function getYearsByRange(initial_year, finish_year) {
  var list = [];

  for (var i = finish_year; i >= initial_year; i--) {
    list.push({
      label: "".concat(i),
      value: "".concat(i)
    });
  }

  return list;
};