var defaultPaymentInformation = {
  id: null,
  user_id: null,
  bank_id: null,
  payment_type: null,
  titular_name: null,
  titular_rut: null,
  account_number: null,
  account_type: null,
  card_expiration: null
};
export default defaultPaymentInformation;