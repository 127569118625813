import { generateDate } from "../../polizas/utils";
var defaultClaim = {
  request_date: null,
  claim_date: null,
  claim_number: "",
  forecast_price: null,
  address: "",
  description: "",
  business_name: "",
  name: "",
  email: "",
  phone: "",
  any_inspection: false
};
export var formatDefaultClaim = function formatDefaultClaim(claim) {
  if (!claim) return defaultClaim;
  var _claim$claim_number = claim.claim_number,
      claim_number = _claim$claim_number === void 0 ? "" : _claim$claim_number,
      _claim$forecast_price = claim.forecast_price,
      forecast_price = _claim$forecast_price === void 0 ? null : _claim$forecast_price,
      _claim$address = claim.address,
      address = _claim$address === void 0 ? "" : _claim$address,
      _claim$description = claim.description,
      description = _claim$description === void 0 ? "" : _claim$description,
      _claim$business_name = claim.business_name,
      business_name = _claim$business_name === void 0 ? "" : _claim$business_name,
      _claim$name = claim.name,
      name = _claim$name === void 0 ? "" : _claim$name,
      _claim$email = claim.email,
      email = _claim$email === void 0 ? "" : _claim$email,
      _claim$phone = claim.phone,
      phone = _claim$phone === void 0 ? "" : _claim$phone,
      _claim$status = claim.status,
      status = _claim$status === void 0 ? null : _claim$status,
      _claim$coverage = claim.coverage,
      coverage = _claim$coverage === void 0 ? null : _claim$coverage,
      _claim$final_compensa = claim.final_compensation,
      final_compensation = _claim$final_compensa === void 0 ? null : _claim$final_compensa,
      _claim$any_inspection = claim.any_inspection,
      any_inspection = _claim$any_inspection === void 0 ? false : _claim$any_inspection;
  return {
    claim_number: claim_number,
    forecast_price: forecast_price,
    address: address,
    description: description,
    business_name: business_name,
    name: name,
    email: email,
    phone: phone,
    status: status,
    coverage: coverage,
    final_compensation: final_compensation,
    any_inspection: any_inspection,
    request_date: claim.request_date ? generateDate(claim.request_date) : null,
    claim_date: claim.claim_date ? generateDate(claim.claim_date) : null
  };
};
export var claimStatusOptions = [{
  value: "denounced",
  label: "Denunciado"
}, {
  value: "settlement",
  label: "En Liquidación"
}, {
  value: "closed compensation",
  label: "Cerrado con Indemnización"
}, {
  value: "closed no compensation",
  label: "Cerrado sin Indemnización"
}, {
  value: "closed no records",
  label: "Cerrado por falta de Antecedentes"
}];
export var claimStatusFormatted = function claimStatusFormatted(status) {
  var statusFormatted = claimStatusOptions.find(function (option) {
    return option.value === status;
  });
  return statusFormatted ? statusFormatted.label : "Sin Status";
};