export default [{
  label: "0 UF",
  value: "0"
}, {
  label: "3 UF",
  value: "3"
}, {
  label: "5 UF",
  value: "5"
}, {
  label: "7 UF",
  value: "7"
}, {
  label: "10 UF",
  value: "10"
}, {
  label: "15 UF",
  value: "15"
}, {
  label: "20 UF",
  value: "20"
}, {
  label: "25 UF",
  value: "25"
}, {
  label: "30 UF",
  value: "30"
}, {
  label: "50 UF",
  value: "50"
}];