export var getDifferenceInDays = function getDifferenceInDays(date1, date2) {
  // Difference in Miliseconds
  var differenceInMs = Math.abs(date2.getTime() - date1.getTime()); // Number of milliseconds in a day

  var millisecondsInDay = 1000 * 60 * 60 * 24;
  var differenceInDays = Math.floor(differenceInMs / millisecondsInDay);
  return differenceInDays;
};
export var getFormattedDate = function getFormattedDate(dateUnformatted) {
  var date = new Date(dateUnformatted);
  var day = date.getUTCDate();
  var month = date.getUTCMonth() + 1;
  var year = date.getUTCFullYear();
  var hour = date.getHours();
  var minutes = date.getMinutes();
  var seconds = date.getSeconds();
  var formattedDay = day < 10 ? "0".concat(day) : day;
  var formattedMonth = month < 10 ? "0".concat(month) : month;
  return "".concat(formattedDay, "-").concat(formattedMonth, "-").concat(year, " ").concat(hour, ":").concat(minutes, ":").concat(seconds);
};