import axios from "axios";
export var fetchPolizaRenewals = function fetchPolizaRenewals(poliza_id) {
  return axios.get("/api/v1/poliza_renewals/", {
    params: {
      poliza_id: poliza_id
    }
  }).then(function (response) {
    return response.data;
  });
};
export var fetchPolizaRenewal = function fetchPolizaRenewal(poliza_renewal_id) {
  return axios.get("/api/v1/poliza_renewals/".concat({
    poliza_renewal_id: poliza_renewal_id
  })).then(function (response) {
    return response.data;
  });
};
export var createJson = function createJson(poliza_renewal) {
  return fetch("/api/v1/poliza_renewals", {
    method: "POST",
    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(poliza_renewal)
  });
};

var isObject = function isObject(obj) {
  return obj && typeof obj === "object";
};

var isArray = function isArray(obj) {
  return isObject(obj) && obj instanceof Array;
};

var addToFormData = function addToFormData(formData, object_name, object, key) {
  if (object[key] == null) {
    return;
  }

  if (isArray(object[key])) {
    formData.append("".concat(object_name, "[").concat(key, "]"), object[key]);
  } else if (isObject(object[key])) {
    if (object[key] instanceof Date) {
      formData.append("".concat(object_name, "[").concat(key, "]"), object[key]);
    } else {
      for (var subKey in object[key]) {
        addToFormData(formData, "".concat(object_name, "[").concat(key, "]"), object[key], subKey);
      }
    }
  } else {
    formData.append("".concat(object_name, "[").concat(key, "]"), object[key]);
  }
};

var buildPolizaRenewalData = function buildPolizaRenewalData(poliza_renewal) {
  var formData = new FormData();

  for (var key in poliza_renewal) {
    if (key === "company_conditions_document") {
      formData.append("poliza_renewal_attributes[".concat(key, "]"), poliza_renewal[key]);
    } else {
      addToFormData(formData, "poliza_renewal_attributes", poliza_renewal, key);
    }
  }

  return formData;
};

export var create = function create(poliza_renewal, token) {
  var formData = buildPolizaRenewalData(poliza_renewal);
  return fetch("/api/v1/poliza_renewals", {
    method: "POST",
    body: formData
  });
};
export var update = function update(poliza_renewal, token) {
  var formData = buildPolizaRenewalData(poliza_renewal);
  return fetch("/api/v1/poliza_renewals/".concat(poliza_renewal.id), {
    method: "PUT",
    // DONT USE HEADERS, FETCH DOES NOT SEND MULTIPART DATA IF YOU DO
    body: formData
  });
};
export var finish = function finish(poliza_renewal, token) {
  var formData = buildPolizaRenewalData(poliza_renewal);
  return fetch("/api/v1/poliza_renewals/".concat(poliza_renewal.id, "/finish"), {
    method: "POST",
    // DONT USE HEADERS, FETCH DOES NOT SEND MULTIPART DATA IF YOU DO
    body: formData
  });
};
export var reset = function reset(poliza_renewal, token) {
  var formData = buildPolizaRenewalData(poliza_renewal);
  return fetch("/api/v1/poliza_renewals/".concat(poliza_renewal.id, "/reset"), {
    method: "POST",
    // DONT USE HEADERS, FETCH DOES NOT SEND MULTIPART DATA IF YOU DO
    body: formData
  });
};
export var getIndexQueryKey = function getIndexQueryKey(poliza_id) {
  return ["/api/v1/poliza_renewals/".concat(poliza_id)];
};