import { CurrencyTypes } from "./data";
export var getCurrencyByString = function getCurrencyByString(currency) {
  switch (currency) {
    case "CLP":
      return CurrencyTypes.CLP;

    case "UF":
      return CurrencyTypes.UF;

    case "US":
      return CurrencyTypes.US;

    case "PERCENTAGE":
      return CurrencyTypes.PERCENTAGE;

    default:
      return CurrencyTypes.CLP;
  }
};
export var getSeparatorByCurrency = function getSeparatorByCurrency(currency) {
  switch (currency) {
    case CurrencyTypes.CLP:
      return {
        currency: CurrencyTypes.CLP,
        prefix: "".concat(CurrencyTypes.CLP, " "),
        groupSeparator: ".",
        decimalSeparator: ",",
        decimalScale: 0,
        decimalLimit: 0,
        fixedDecimalLength: 0
      };

    case CurrencyTypes.UF:
      return {
        currency: CurrencyTypes.UF,
        prefix: "".concat(CurrencyTypes.UF, " "),
        groupSeparator: ".",
        decimalSeparator: ",",
        decimalScale: 3,
        decimalLimit: 3,
        fixedDecimalLength: 3
      };

    case CurrencyTypes.US:
      return {
        currency: CurrencyTypes.US,
        prefix: "".concat(CurrencyTypes.US, " "),
        groupSeparator: ".",
        decimalSeparator: ",",
        decimalScale: 3,
        decimalLimit: 3,
        fixedDecimalLength: 3
      };

    case CurrencyTypes.PERCENTAGE:
      return {
        currency: CurrencyTypes.PERCENTAGE,
        suffix: "".concat(CurrencyTypes.PERCENTAGE, " "),
        groupSeparator: ".",
        decimalSeparator: ",",
        decimalScale: 3,
        decimalLimit: 3,
        fixedDecimalLength: 3
      };

    default:
      return {
        currency: CurrencyTypes.UF,
        prefix: "".concat(CurrencyTypes.UF, " "),
        groupSeparator: ".",
        decimalSeparator: ",",
        decimalScale: 3,
        decimalLimit: 3,
        fixedDecimalLength: 3
      };
  }
};
export var formatValue = function formatValue(value, decimalLimit) {
  var formattedValue = value ? value.replace(",", ".") : "0";
  var exceedDecimalCount = decimalCount(parseFloat(formattedValue)) > decimalLimit;
  if (exceedDecimalCount) return parseFloat(parseFloat(formattedValue).toFixed(decimalLimit));
  return parseFloat(formattedValue);
};
export var formatDecimal = function formatDecimal(number, decimalLimit) {
  var exceedDecimalCount = decimalCount(number) > decimalLimit;
  if (exceedDecimalCount) return parseFloat(parseFloat(number.toString()).toFixed(decimalLimit));
  return number;
};
export var decimalCount = function decimalCount(number) {
  // Convert to String
  var numStr = String(number); // String Contains Decimal

  if (numStr.includes(".")) {
    return numStr.split(".")[1].length;
  } // String Does Not Contain Decimal


  return 0;
}; // 

export var roundTo = function roundTo(n, digits) {
  var rounded_value = Math.round(n * Math.pow(10, digits)) / Math.pow(10, digits);

  if (isNaN(rounded_value)) {
    rounded_value = 0;
  }

  return rounded_value;
};