export function rutValidate(complete_rut) {
  if (complete_rut) {
    complete_rut = complete_rut.replace("‐", "-").replace(/\./g, "");
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(complete_rut)) return false;
    var tmp = complete_rut.split("-");
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == "K") digv = "k";
    return dv(rut) == digv;
  } else {
    return complete_rut;
  }
}
export function rutValidateWithoutPoints(complete_rut) {
  if (complete_rut) {
    complete_rut = complete_rut.replace("‐", "-");
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(complete_rut)) return false;
    var tmp = complete_rut.split("-");
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == "K") digv = "k";
    return dv(rut) == digv;
  } else {
    return complete_rut;
  }
}
export function clearRut(rut) {
  var rutCleaned = rut.replace(/\./g, "").replace(/-/g, "");
  return rutCleaned;
}
export function formatRut(rut) {
  var current = rut.replace(/^0+/, "");
  var rutPoints = "";

  if (current != "" && current.length > 1) {
    var withoutPoints = current.replace(/\./g, "");
    var currentClear = withoutPoints.replace(/-/g, "");
    var start = currentClear.substring(0, currentClear.length - 1);
    var i = 0;
    var j = 1;

    for (i = start.length - 1; i >= 0; i--) {
      var letter = start.charAt(i);
      rutPoints = letter + rutPoints;

      if (j % 3 == 0 && j <= start.length - 1) {
        rutPoints = "." + rutPoints;
      }

      j++;
    }

    var _dv = currentClear.substring(currentClear.length - 1);

    rutPoints = rutPoints + "-" + _dv;
  }

  return rutPoints;
}
export function checkRut(rut) {
  // Despejar Puntos
  var valor = rut.replace(".", ""); // Despejar Guión

  valor = valor.replace("-", ""); // Aislar Cuerpo y Dígito Verificador

  var cuerpo = valor.slice(0, -1);
  var dv = valor.slice(-1).toUpperCase(); // Formatear RUN

  rut = cuerpo + "-" + dv; // Si no cumple con el mínimo ej. (n.nnn.nnn)

  if (cuerpo.length < 7) {
    return rut;
  } // Calcular Dígito Verificador


  var suma = 0;
  var multiplo = 2; // Para cada dígito del Cuerpo

  for (var i = 1; i <= cuerpo.length; i++) {
    // Obtener su Producto con el Múltiplo Correspondiente
    var index = multiplo * valor.charAt(cuerpo.length - i); // Sumar al Contador General

    suma = suma + index; // Consolidar Múltiplo dentro del rango [2,7]

    if (multiplo < 7) {
      multiplo = multiplo + 1;
    } else {
      multiplo = 2;
    }
  } // Calcular Dígito Verificador en base al Módulo 11


  var dvEsperado = 11 - suma % 11; // Casos Especiales (0 y K)

  dv = dv == "K" ? 10 : dv;
  dv = dv == 0 ? 11 : dv; // Validar que el Cuerpo coincide con su Dígito Verificador

  if (dvEsperado != dv) {
    return rut;
  } // Si todo sale bien, eliminar errores (decretar que es válido)


  return rut;
} // FUNCTIONS HELPERS

var dv = function dv(T) {
  var M = 0,
      S = 1;

  for (; T; T = Math.floor(T / 10)) {
    S = (S + T % 10 * (9 - M++ % 6)) % 11;
  }

  return S ? S - 1 : "k";
};