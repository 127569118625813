import axios from "axios";
export var fetchPolizaEndorsements = function fetchPolizaEndorsements(poliza_id) {
  return axios.get("/api/v1/poliza_endorsements/", {
    params: {
      poliza_id: poliza_id
    }
  }).then(function (response) {
    return response.data;
  });
};
export var fetchPolizaEndorsement = function fetchPolizaEndorsement(poliza_endorsement_id) {
  return axios.get("/api/v1/poliza_endorsements/".concat({
    poliza_endorsement_id: poliza_endorsement_id
  })).then(function (response) {
    return response.data;
  });
};
export var createJson = function createJson(poliza_endorsement) {
  return fetch("/api/v1/poliza_endorsements", {
    method: "POST",
    headers: {
      Accept: "application/json",
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(poliza_endorsement)
  });
};

var isObject = function isObject(obj) {
  return obj && typeof obj === "object";
};

var isArray = function isArray(obj) {
  return isObject(obj) && obj instanceof Array;
};

var addToFormData = function addToFormData(formData, object_name, object, key) {
  if (object[key] == null) {
    return;
  }

  if (isArray(object[key])) {
    if (["emails", "addresses"].includes(key)) {
      for (var i = 0; i < object[key].length; i++) {
        for (var sub_key in object[key][i]) {
          if (object[key][i][sub_key] !== undefined && object[key][i][sub_key] !== null) {
            formData.append("".concat(object_name, "[").concat(key, "][]").concat(sub_key), object[key][i][sub_key]);
          }
        }
      }
    } else {
      formData.append("".concat(object_name, "[").concat(key, "]"), object[key]);
    }
  } else if (isObject(object[key])) {
    if (object[key] instanceof Date) {
      formData.append("".concat(object_name, "[").concat(key, "]"), object[key]);
    } else {
      for (var subKey in object[key]) {
        addToFormData(formData, "".concat(object_name, "[").concat(key, "]"), object[key], subKey);
      }
    }
  } else {
    formData.append("".concat(object_name, "[").concat(key, "]"), object[key]);
  }
};

var buildPolizaEndorsementFormData = function buildPolizaEndorsementFormData(poliza_endorsement) {
  var formData = new FormData();

  for (var key in poliza_endorsement) {
    addToFormData(formData, "poliza_endorsement", poliza_endorsement, key);
  }

  return formData;
};

export var create = function create(poliza_endorsement) {
  var formData = buildPolizaEndorsementFormData(poliza_endorsement);
  return fetch("/api/v1/poliza_endorsements", {
    method: "POST",
    headers: {
      Accept: "application/json"
    },
    body: formData
  });
};
export var update = function update(poliza_endorsement) {
  var formData = buildPolizaEndorsementFormData(poliza_endorsement);
  return fetch("/api/v1/poliza_endorsements/".concat(poliza_endorsement.id), {
    method: "PUT",
    headers: {
      Accept: "application/json"
    },
    body: formData
  });
};
export var getIndexQueryKey = function getIndexQueryKey(poliza_id) {
  return ["/api/v1/poliza_endorsements/".concat(poliza_id)];
};