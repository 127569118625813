export default [{
  label: "Concreto",
  value: "Concreto"
}, {
  label: "Ladrillo",
  value: "Ladrillo"
}, {
  label: "Mixto",
  value: "Mixto"
}, {
  label: "Madera",
  value: "Madera"
}, {
  label: "Adobe",
  value: "Adobe"
}, {
  label: "Otro",
  value: "Otro"
}];